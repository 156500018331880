import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  ArrowRight,
  Linkedin,
  MessageSquare,
  Database,
  Shield,
  ChartBar,
} from "lucide-react";
import { ReactTyped as Typed } from "react-typed";

const Home = () => {
  return (
    <div className="home-container">
      {/* Hero Section */}
      <section className="hero d-flex align-items-center">
        <div className="container text-center">
          <h1 className="fw-bold display-4 text-glow">Mohamed Gad</h1>
          <h2 className="gradient-text">
            <Typed
              strings={[
                "Political Scientist",
                "Transportation Expert",
                "Data Scientist",
              ]}
              typeSpeed={60}
              backSpeed={40}
              loop
            />
          </h2>
          <p>
            <strong>Political Scientist</strong> |{" "}
            <strong>Transportation Expert</strong> |{" "}
            <strong>Data Scientist</strong>
            <br /> I am a <strong>political scientist by heart</strong>, a{" "}
            <strong>transportation expert by profession</strong>, and a{" "}
            <strong>data scientist by passion</strong>. I hold a Bachelor of
            Arts in Political Science, specializing in{" "}
            <strong>Policy Making and Analysis</strong>, and a Master of Science
            in Data Science, focusing on{" "}
            <strong>NLP, decision-making, and optimization</strong>. My
            entrepreneurial journey began at the age of 22, and I continue to
            explore <strong>innovative ventures</strong> that merge{" "}
            <strong>policy, mobility, and AI-driven insights</strong>. With
            expertise in{" "}
            <strong>
              executive-level management, trading and investments, and startup
              development
            </strong>
            , I leverage{" "}
            <strong>data science to drive unbiased, informed decisions</strong>.
            While I value historical data for its lessons, my true passion lies
            in <strong>forecasting the future</strong>, anticipating trends,
            mitigating risks, and creating solutions that shape tomorrow.
          </p>
          <Link to="/aboutme" className="btn btn-primary btn-lg mt-3">
            More About My Journey <ArrowRight size={18} className="ms-1" />
          </Link>
        </div>
      </section>

      {/* What I Do */}
      <section className="services container my-5">
        <h2 className="text-center mb-4">Projects I've Built</h2>
        <div className="row text-center">
          <div className="col-md-4">
            <Database size={50} className="text-cyan mb-3" />
            <h4>YouTube Trending Analysis</h4>
            <p>
              Built an AI-powered tool that analyzes and predicts YouTube video
              trends using NLP and machine learning.
            </p>
          </div>
          <div className="col-md-4">
            <Shield size={50} className="text-cyan mb-3" />
            <h4>AI-Powered Fraud Detection</h4>
            <p>
              Developed a machine learning model to detect and prevent
              fraudulent activities in financial transactions.
            </p>
          </div>
          <div className="col-md-4">
            <ChartBar size={50} className="text-cyan mb-3" />
            <h4>Market Prediction Analytics</h4>
            <p>
              Designed a predictive analytics platform that forecasts stock
              market trends using deep learning techniques.
            </p>
          </div>
        </div>
        <div className="text-center mt-4">
          <Link to="/projects" className="btn btn-outline-primary btn-lg">
            View All Projects <ArrowRight size={18} className="ms-1" />
          </Link>
        </div>
      </section>

      {/* Thought Leadership */}
      <section className="thought-leadership text-center my-5">
        <h3>My Philosophy</h3>
        <blockquote className="philosophy-text">
          "AI and data science should empower decision-making, not dictate it.
          Data is a powerful tool, but without human intuition, ethics, and
          critical thinking, it can lead to blind decisions and unintended
          consequences. I believe in using AI to enhance governance, optimize
          companies, and drive business growth while ensuring fairness,
          transparency, and long-term impact. The goal is not just to analyze
          the past, but to forecast a better future."
        </blockquote>
      </section>

      {/* Call to Action */}
      <section className="cta text-center my-5">
        <h3>Let's Connect</h3>
        <p>Follow my latest insights or get in touch.</p>
        <a
          href="https://www.linkedin.com/in/mohamed-gad-6b286b1b6/"
          className="btn btn-dark me-3"
        >
          <Linkedin size={18} className="me-1" /> Connect on LinkedIn
        </a>
        <Link to="/contactus" className="btn btn-success">
          <MessageSquare size={18} className="me-1" /> Contact Me
        </Link>
      </section>
    </div>
  );
};

export default Home;
