import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const PostAnArticle = () => {
  const navigate = useNavigate();
  const [articleData, setArticleData] = useState({
    title: "",
    subtitle: "",
    publishedBy: "",
    image: "",
    demoLink: "",
    sections: {
      Abstract: "",
      Introduction: "",
      Objectives: [],
      Methodology: [],
      Features: [],
      Challenges: [],
      Results_Impact: "",
      Conclusion_Future_Work: "",
    },
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const formattedName = name.replace(/[\s&]/g, "_"); // Ensure consistent key formatting

    if (
      ["Objectives", "Methodology", "Features", "Challenges"].includes(
        formattedName
      )
    ) {
      setArticleData((prevState) => ({
        ...prevState,
        sections: {
          ...prevState.sections,
          [formattedName]: value.split(","),
        },
      }));
    } else if (formattedName in articleData.sections) {
      setArticleData((prevState) => ({
        ...prevState,
        sections: { ...prevState.sections, [formattedName]: value },
      }));
    } else {
      setArticleData((prevState) => ({ ...prevState, [formattedName]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        process.env.REACT_APP_POST_ARTICLES,
        articleData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        setSuccess("Article posted successfully!");
        setArticleData({
          title: "",
          subtitle: "",
          publishedBy: "",
          image: "",
          demoLink: "",
          sections: {
            Abstract: "",
            Introduction: "",
            Objectives: [],
            Methodology: [],
            Features: [],
            Challenges: [],
            Results_Impact: "",
            Conclusion_Future_Work: "",
          },
        });

        setTimeout(() => {
          navigate("/projects");
        }, 2000);
      }
    } catch (error) {
      console.error("Error posting article:", error);
      setError("Failed to post the article. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container my-5">
      <h1 className="fw-bold text-center">Post an Article</h1>

      <div className="row justify-content-center">
        <div className="col-md-8">
          <form
            onSubmit={handleSubmit}
            className="p-4 border rounded shadow-sm bg-light"
          >
            {error && <p className="text-danger">{error}</p>}
            {success && <p className="text-success">{success}</p>}

            <div className="mb-3">
              <label className="form-label">Title</label>
              <input
                name="title"
                type="text"
                className="form-control"
                placeholder="Enter article title"
                required
                value={articleData.title}
                onChange={handleChange}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Subtitle</label>
              <input
                name="subtitle"
                type="text"
                className="form-control"
                placeholder="Enter article subtitle"
                required
                value={articleData.subtitle}
                onChange={handleChange}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Published By</label>
              <input
                name="publishedBy"
                type="text"
                className="form-control"
                placeholder="Enter author name"
                required
                value={articleData.publishedBy}
                onChange={handleChange}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Image URL</label>
              <input
                name="image"
                type="text"
                className="form-control"
                placeholder="Enter image URL"
                required
                value={articleData.image}
                onChange={handleChange}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Demo Link</label>
              <input
                name="demoLink"
                type="text"
                className="form-control"
                placeholder="Enter demo link"
                value={articleData.demoLink}
                onChange={handleChange}
              />
            </div>

            {/* Sections */}
            <h3 className="mt-4">Article Sections</h3>

            <div className="mb-3">
              <label className="form-label">Abstract</label>
              <textarea
                name="Abstract"
                className="form-control"
                placeholder="Enter article abstract"
                rows="3"
                required
                value={articleData.sections.Abstract}
                onChange={handleChange}
              ></textarea>
            </div>

            <div className="mb-3">
              <label className="form-label">Introduction</label>
              <textarea
                name="Introduction"
                className="form-control"
                placeholder="Enter article introduction"
                rows="3"
                required
                value={articleData.sections.Introduction}
                onChange={handleChange}
              ></textarea>
            </div>

            {/* Arrays as comma-separated values */}
            <div className="mb-3">
              <label className="form-label">Objectives (comma-separated)</label>
              <input
                name="Objectives"
                type="text"
                className="form-control"
                placeholder="Enter objectives"
                value={articleData.sections.Objectives.join(",")}
                onChange={handleChange}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">
                Methodology (comma-separated)
              </label>
              <input
                name="Methodology"
                type="text"
                className="form-control"
                placeholder="Enter methodology"
                value={articleData.sections.Methodology.join(",")}
                onChange={handleChange}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Features (comma-separated)</label>
              <input
                name="Features"
                type="text"
                className="form-control"
                placeholder="Enter features"
                value={articleData.sections.Features.join(",")}
                onChange={handleChange}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Challenges (comma-separated)</label>
              <input
                name="Challenges"
                type="text"
                className="form-control"
                placeholder="Enter challenges"
                value={articleData.sections.Challenges.join(",")}
                onChange={handleChange}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Results & Impact</label>
              <textarea
                name="Results_Impact"
                className="form-control"
                placeholder="Enter results & impact"
                rows="3"
                required
                value={articleData.sections.Results_Impact}
                onChange={handleChange}
              ></textarea>
            </div>

            <div className="mb-3">
              <label className="form-label">Conclusion & Future Work</label>
              <textarea
                name="Conclusion_Future_Work"
                className="form-control"
                placeholder="Enter conclusion & future work"
                rows="3"
                required
                value={articleData.sections.Conclusion_Future_Work}
                onChange={handleChange}
              ></textarea>
            </div>

            <button
              type="submit"
              className="btn btn-primary w-100"
              disabled={loading}
            >
              {loading ? "Submitting..." : "Post Article"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PostAnArticle;
