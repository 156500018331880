import React from "react";
import { Link } from "react-router-dom";
import { ArrowRight } from "lucide-react";

const Experiences = () => {
  return (
    <div className="container my-5">
      {/* Page Title */}
      <section className="text-center mb-5">
        <h1 className="fw-bold display-4">Professional Experience</h1>
      </section>

      {/* Professional Summary */}
      <section className="my-5">
        <h2 className="text-center mb-4">Professional Summary</h2>
        <p className="text-center">
          Executive Leader, Data Scientist & Business Owner with expertise in
          leadership, AI, and strategic planning. Currently managing Mobility
          Services Data at Central Florida Regional Transportation Authority
          (LYNX), while also leading Old Alex Hub and Trans Voyage Taxi. A
          specialist in AI/ML-driven decision-making, financial forecasting, and
          business development with deep knowledge in Python, R, SQL, BI tools,
          and full-stack development (MERN).
        </p>
      </section>

      {/* Work Experience Section */}
      <section className="my-5">
        <h2 className="text-center mb-4">Work Experience</h2>
        <div className="row">
          <div className="col-md-12">
            <div className="experience-item">
              <h4>
                Manager of Mobility Services Data - Central Florida Regional
                Transportation Authority (LYNX)
              </h4>
              <p className="text-muted">(10/2021 - Present)</p>
              <ul>
                <li>
                  Manages cross-functional projects using advanced data
                  monitoring and analysis techniques to gain insights for
                  guiding strategic decisions and uncovering optimization
                  opportunities for operational performance and service
                  improvement.{" "}
                </li>
                <li>
                  Monitors audits and operations data analysis to ensure that
                  Lynx operations comply with ADA and other regulations.{" "}
                </li>
                <li>
                  Developed a reporting criterion and monitors service delivery
                  and performance through reports prepared by the data team and
                  myself.{" "}
                </li>
                <li>
                  Resolves service issues by making recommendations for
                  solutions to improve operational efficiency and effectiveness.{" "}
                </li>
                <li>Monitors the operating and capital budgets. </li>
                <li>
                  Provides data analysis and support to prepare the AOR (Annual
                  Operating Report) and NTD (National Transit Database) reports.{" "}
                </li>
              </ul>
            </div>

            <div className="experience-item">
              <h4>Executive Director of Operations - Trans Voyage Taxi</h4>
              <p className="text-muted">(08/2018 - Present)</p>
              <ul>
                <li>
                  Lead business strategy, financial management, and operational
                  efficiency, driving profitability and sustainable growth.
                </li>
                <li>
                  Utilize data analysis and predictive modeling to optimize
                  processes, forecast financial performance, and improve
                  decision-making.
                </li>
                <li>
                  Oversee HR operations, recruitment, and workforce development,
                  fostering a high-performance culture.
                </li>
                <li>
                  Develop and implement go-to-market strategies to enhance
                  service quality, client satisfaction, and competitive
                  positioning.
                </li>
                <li>
                  Ensure regulatory compliance, risk management, and budget
                  control, maintaining operational integrity.
                </li>
                <li>
                  Leverage business intelligence tools and market analysis to
                  drive strategic initiatives and maximize efficiency.
                </li>
              </ul>
            </div>

            <div className="experience-item">
              <h4>
                Talent Acquisition Manager - Metro Transportation Planning and
                Solutions Group
              </h4>
              <p className="text-muted">(04/2017 – 05/2018)</p>
              <ul>
                <li>
                  Led talent acquisition strategies, optimizing sourcing
                  channels and recruitment processes to attract top-tier
                  candidates.
                </li>
                <li>
                  Developed and executed data-driven hiring strategies,
                  leveraging analytics to improve workforce planning and
                  retention.
                </li>
                <li>
                  Enhanced onboarding programs and training materials, aligning
                  new hires with company culture and performance goals.
                </li>
                <li>
                  Implemented predictive analytics to forecast talent needs,
                  ensuring alignment with business growth objectives.
                </li>
                <li>
                  Strengthened employer branding and candidate engagement,
                  improving applicant quality and hiring efficiency.
                </li>
                <li>
                  Collaborated with executive leadership to refine recruitment
                  strategies, driving diversity, efficiency, and long-term
                  talent success.
                </li>
              </ul>
            </div>

            <div className="experience-item">
              <h4>General Manager - Green Taxi Cooperative</h4>
              <p className="text-muted">(03/2016 – 04/2017)</p>
              <ul>
                <li>
                  Led business operations, financial management, and strategic
                  planning, driving profitability and efficiency.
                </li>
                <li>
                  Utilized data analysis and performance metrics to optimize
                  workflows, enhance service delivery, and improve customer
                  satisfaction.
                </li>
                <li>
                  Managed budgeting, cost control, and revenue forecasting,
                  ensuring financial stability and growth.
                </li>
                <li>
                  Developed and implemented marketing and business strategies,
                  increasing market share and competitive positioning.
                </li>
                <li>
                  Oversaw HR operations, recruitment, and employee development,
                  fostering a strong organizational culture.
                </li>
                <li>
                  Ensured regulatory compliance, risk mitigation, and audit
                  readiness, maintaining operational integrity.
                </li>
              </ul>
            </div>

            <div className="experience-item">
              <h4>General Manager - Mile High Cab, Inc</h4>
              <p className="text-muted">(02/2014 – 03/2016)</p>
              <ul>
                <li>
                  Led business operations, financial management, and strategic
                  growth initiatives, improving profitability and efficiency.
                </li>
                <li>
                  Utilized data-driven decision-making to optimize workflows,
                  enhance service delivery, and increase customer satisfaction.
                </li>
                <li>
                  Managed budgeting, cost control, and revenue forecasting,
                  ensuring financial stability and sustainable growth.
                </li>
                <li>
                  Developed and executed marketing and business strategies,
                  expanding market presence and competitive positioning.
                </li>
                <li>
                  Oversaw HR operations, recruitment, and workforce development,
                  fostering a culture of excellence and high performance.
                </li>
                <li>
                  Ensured regulatory compliance, risk management, and audit
                  readiness, maintaining operational integrity and adherence to
                  industry standards.
                </li>
              </ul>
            </div>

            <div className="experience-item">
              <h4>General Store Manager - Ralston Convenience Store</h4>
              <p className="text-muted">(08/2012 - 07/2014)</p>
              <ul>
                <li>
                  Orchestrated weekly meetings to set sales and service
                  objectives, enhancing team alignment and performance.
                </li>
                <li>
                  Utilized performance data for continuous operational
                  improvements, addressing real-time business needs.
                </li>
                <li>
                  Developed and enforced policies to boost efficiency and
                  adaptability, with strategic scheduling to meet customer
                  demands.
                </li>
                <li>
                  Enhanced team dynamics and productivity through a culture of
                  collaboration and mutual success.
                </li>
                <li>
                  Assessed supplier performance for cost efficiency,
                  contributing to operational excellence.
                </li>
                <li>
                  Directed sales strategies and budget management, achieving
                  financial targets and sustainability.
                </li>
                <li>
                  Led the development of a new store, from location selection to
                  team building, ensuring customer service excellence.
                </li>
                <li>
                  Implemented security measures to safeguard assets, alongside
                  innovative strategies for inventory management and customer
                  feedback utilization.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Education and Awards Side by Side */}
      <section className="row align-items-center my-5">
        <div className="col-md-6">
          <h2 className="text-center mb-4">Education</h2>
          <p>
            🎓 <strong>Master of Science in Data Science</strong> (Expected
            07/2025) - Eastern University, PA
          </p>
          <p>
            🎓 <strong>Bachelor of Arts in Political Science</strong>{" "}
            (2014-2020) - Metropolitan State University of Denver
          </p>
        </div>
        <div className="col-md-6 text-center">
          <h2 className="text-center mb-4">Awards & Recognitions</h2>
          <ul>
            <li>
              🏆 Certificate of Appreciation - Transportation Advisory Board at
              Denver International Airport (2019)
            </li>
            <li>
              📜 David Langston Excellence Award - Central Florida Community
              College (2009)
            </li>
          </ul>
        </div>
      </section>

      {/* Language Proficiency */}
      <section className="text-center my-5">
        <h2>Languages</h2>
        <p>🗣️ Arabic - Fluent (Reading, Writing, Conversation)</p>
        <p>🗣️ English - Fluent (Reading, Writing, Conversation)</p>
      </section>

      {/* Projects */}
      <section className="text-center my-5">
        <div className="text-center mt-4">
          <h2>Projects</h2>
          <Link to="/projects" className="btn btn-outline-primary btn-lg">
            View All Projects <ArrowRight size={18} className="ms-1" />
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Experiences;
