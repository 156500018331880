import React, { useEffect, useState } from "react";
import axios from "axios";

const TechNews = () => {
  const [newsData, setNewsData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchNews();
  }, [page]);

  const fetchNews = async () => {
    setLoading(true);
    setError("");

    try {
      const response = await axios.post(
        process.env.REACT_APP_NEWS_API,
        {
          action: "getArticles",
          keyword: ["Tesla Inc", "AI", "Apple"],
          articlesPage: page,
          articlesCount: 9,
          articlesSortBy: "date",
          articlesSortByAsc: false,
          articlesArticleBodyLen: -1,
          resultType: "articles",
          forceMaxDataTimeWindow: 31,
          apiKey: process.env.REACT_APP_NEWS_API_CODE,
          lang: "eng",
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.data.articles && response.data.articles.results) {
        setNewsData((prev) => [...prev, ...response.data.articles.results]);
      }
    } catch (err) {
      console.error("Error fetching news:", err);
      setError(
        "Failed to load news articles. Please check your API key and configuration."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container my-5">
      <h2 className="text-center mb-4">Tech News</h2>

      {error && <p className="text-danger text-center">{error}</p>}

      <div className="row">
        {newsData.length > 0 ? (
          newsData.map((article, index) => (
            <div key={index} className="col-md-4 mb-4">
              <div className="card shadow-sm h-100 d-flex flex-column">
                {article.image && (
                  <img
                    src={article.image}
                    className="card-img-top"
                    alt={article.title}
                  />
                )}
                <div className="card-body d-flex flex-column">
                  <h5 className="card-title">{article.title}</h5>

                  {/* Author & Source */}
                  <p className="text-muted mb-1">
                    <strong>By:</strong> {article.author || "Unknown"} |
                    <strong> Source:</strong> {article.source?.title || "N/A"}
                  </p>

                  {/* Published Date */}
                  <p className="text-muted mb-1">
                    <strong>Published:</strong>{" "}
                    {article.date
                      ? new Date(article.date).toLocaleDateString()
                      : "Unknown"}
                  </p>

                  {/* Article Description */}
                  <p className="card-text flex-grow-1">
                    {article.body
                      ? article.body.substring(0, 200) + "..."
                      : "No description available."}
                  </p>

                  {/* Read More Button (Aligned to Bottom) */}
                  <button
                    className="btn btn-primary mt-auto"
                    onClick={() =>
                      window.open(article.url, "_blank", "noopener,noreferrer")
                    }
                  >
                    Read More
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center">Loading news...</p>
        )}
      </div>

      <div className="text-center mt-4">
        <button
          className="btn btn-secondary"
          onClick={() => setPage((prev) => prev + 1)}
          disabled={loading}
        >
          {loading ? "Loading..." : "Load More"}
        </button>
      </div>
    </div>
  );
};

export default TechNews;
