import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const ProjectDetails = () => {
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_GET_ONE_ARTICLE}/${projectId}`
        );

        if (response.status === 200) {
          setProject(response.data.article);
        } else {
          setError("Project not found.");
        }
      } catch (error) {
        console.error("Error fetching project details:", error);
        setError("Failed to load project details. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchProject();
  }, [projectId]);

  if (loading) {
    return <div className="text-center my-5">Loading project details...</div>;
  }

  if (error) {
    return <div className="text-danger text-center my-5">{error}</div>;
  }

  if (!project) {
    return <div className="text-center my-5">No project data available.</div>;
  }

  return (
    <div className="container my-5">
      <h1 className="fw-bold text-center">{project.title}</h1>
      <h3 className="text-center text-muted">{project.subtitle}</h3>
      <img
        src={project.image}
        alt={project.title}
        className="img-fluid mx-auto d-block rounded shadow my-4"
        style={{ maxHeight: "400px" }}
      />

      <p className="text-center">
        <strong>Published By:</strong> {project.publishedBy}
      </p>
      <p className="text-center">
        <strong>Published On:</strong>{" "}
        {new Date(project.date).toLocaleDateString()}
      </p>

      <section className="mt-4">
        <h3>Abstract</h3>
        <p>{project.sections?.Abstract}</p>
      </section>

      <section className="mt-4">
        <h3>Introduction</h3>
        <p>{project.sections?.Introduction}</p>
      </section>

      <section className="mt-4">
        <h3>Objectives</h3>
        <ul>
          {project.sections?.Objectives?.map((obj, index) => (
            <li key={index}>{obj}</li>
          ))}
        </ul>
      </section>

      <section className="mt-4">
        <h3>Methodology</h3>
        <ul>
          {project.sections?.Methodology?.map((method, index) => (
            <li key={index}>{method}</li>
          ))}
        </ul>
      </section>

      <section className="mt-4">
        <h3>Features</h3>
        <ul>
          {project.sections?.Features?.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
      </section>

      <section className="mt-4">
        <h3>Challenges</h3>
        <ul>
          {project.sections?.Challenges?.map((challenge, index) => (
            <li key={index}>{challenge}</li>
          ))}
        </ul>
      </section>

      <section className="mt-4">
        <h3>Results & Impact</h3>
        <p>{project.sections?.["Results_Impact"]}</p>
      </section>

      <section className="mt-4">
        <h3>Conclusion & Future Work</h3>
        <p>{project.sections?.["Conclusion_Future_Work"]}</p>
      </section>

      {project.demoLink && (
        <div className="text-center mt-4">
          <a
            href={project.demoLink}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-primary"
          >
            View Live Demo
          </a>
        </div>
      )}
    </div>
  );
};

export default ProjectDetails;
