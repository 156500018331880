import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import axios from "axios";
import Layout from "../components/Layout";
import Home from "../routes/Home";
import AboutMe from "../routes/AboutMe";
import Contact from "../routes/Contact";
import Experiances from "../routes/Experiances";
import Login from "../routes/Login";
import NewContent from "../routes/NewContent";
import Projects from "../routes/Projects";
import TechNews from "../routes/TechNews";
import ProjectDetails from "../routes/ProjectDetails";
import PostAnArticle from "../routes/PostAnArticle";

// Function to log interactions
const logInteraction = async (
  eventType,
  targetElement = null,
  pageUrl = null
) => {
  try {
    await axios.post(process.env.REACT_APP_COLLECTION, {
      sessionId: localStorage.getItem("sessionId") || generateSessionId(),
      eventType,
      targetElement,
      pageUrl: pageUrl || window.location.href,
    });
  } catch (error) {
    console.error("Error logging interaction:", error);
  }
};

const generateSessionId = () => {
  if (!localStorage.getItem("sessionId")) {
    const sessionId = Date.now() + Math.random().toString(36).substr(2, 9);
    localStorage.setItem("sessionId", sessionId);
    return sessionId;
  }
  return localStorage.getItem("sessionId");
};

const TrackingWrapper = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    logInteraction("page_view", null, location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    // Log clicks on the entire document
    const handleClick = (event) => {
      const targetElement = event.target.tagName;
      logInteraction("click", targetElement);
    };

    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, []);

  return children;
};

// Main Route Manager
const RouteManager = () => {
  return (
    <BrowserRouter>
      <TrackingWrapper>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="aboutme" element={<AboutMe />} />
            <Route path="contactus" element={<Contact />} />
            <Route path="experiances" element={<Experiances />} />
            <Route path="login" element={<Login />} />
            <Route path="newcontent" element={<NewContent />} />
            <Route path="projects" element={<Projects />} />
            <Route path="projects/:projectId" element={<ProjectDetails />} />
            <Route path="technews" element={<TechNews />} />
            <Route path="postanarticle" element={<PostAnArticle />} />
          </Route>
        </Routes>
      </TrackingWrapper>
    </BrowserRouter>
  );
};

export default RouteManager;
