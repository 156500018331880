import { Outlet, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import {
  Sun,
  Moon,
  Home,
  BookOpen,
  Briefcase,
  Code,
  Mail,
  Cpu,
  Newspaper,
} from "lucide-react";

const Layout = () => {
  const [darkMode, setDarkMode] = useState(true);

  useEffect(() => {
    document.body.classList.toggle("dark-mode", darkMode);
  }, [darkMode]);

  return (
    <div className={`min-vh-100 ${darkMode ? "dark-theme" : "light-theme"}`}>
      {/* Navigation Bar */}
      <nav
        className={`navbar navbar-expand-lg ${
          darkMode ? "bg-dark navbar-dark" : "bg-light navbar-light shadow-sm"
        }`}
      >
        <div className="container">
          {/* Brand with Icon */}
          <Link className="navbar-brand d-flex align-items-center" to="/">
            <Cpu
              size={28}
              className={darkMode ? "text-cyan" : "text-primary"}
            />
            <span className="ms-2 fw-bold" style={{ fontSize: "1.2rem" }}>
              MG
            </span>
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link className="nav-link d-flex align-items-center" to="/">
                  <Home size={18} className="me-1" /> Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link d-flex align-items-center"
                  to="/aboutme"
                >
                  <BookOpen size={18} className="me-1" /> About
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link d-flex align-items-center"
                  to="/experiances"
                >
                  <Briefcase size={18} className="me-1" /> Experiences
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link d-flex align-items-center"
                  to="/projects"
                >
                  <Code size={18} className="me-1" /> Projects
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link d-flex align-items-center"
                  to="/technews"
                >
                  <Newspaper size={18} className="me-1" /> Tech News
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link d-flex align-items-center"
                  to="/contactus"
                >
                  <Mail size={18} className="me-1" /> Contact
                </Link>
              </li>
            </ul>
          </div>

          {/* Dark Mode Toggle */}
          <button
            className="btn btn-outline-light ms-3 toggle-darkmode"
            onClick={() => setDarkMode(!darkMode)}
          >
            {darkMode ? <Sun size={20} /> : <Moon size={20} />}
          </button>
        </div>
      </nav>

      {/* Main Content */}
      <div className="container mt-4">
        <Outlet />
      </div>

      {/* Footer */}
      <footer
        className={`text-center py-3 mt-5 ${
          darkMode ? "footer-dark" : "footer-light"
        }`}
      >
        <p className="mb-0">
          &copy; {new Date().getFullYear()} Mohamed Gad | AI Strategist & Tech
          Innovator
        </p>
      </footer>

      {/* Custom Styling */}
      <style>
        {`
          /* Dark Theme */
          .dark-theme {
            background: #121212;
            color: #ddd;
          }
          
          .light-theme {
            background: #f8f9fa;
            color: #333;
          }
          
          /* Navbar */
          .navbar-dark {
            background: #1a1a1a !important;
            border-bottom: 1px solid #333;
          }

          .navbar-dark .nav-link {
            color: #ddd !important;
            transition: color 0.3s ease-in-out;
          }

          .navbar-dark .nav-link:hover {
            color: #00E0FF !important;
          }

          .navbar-light .nav-link {
            color: #333 !important;
          }

          .navbar-light .nav-link:hover {
            color: #007bff !important;
          }

          /* Footer */
          .footer-dark {
            background: #1a1a1a;
            color: #00FF99;
          }

          .footer-light {
            background: #f8f9fa;
            color: #333;
          }

          /* Dark Mode Toggle */
          .toggle-darkmode {
            background: transparent;
            border: 1px solid #00E0FF;
          }

          .toggle-darkmode:hover {
            background: #00E0FF;
            color: #000;
          }

          /* Brand Styling */
          .text-cyan {
            color: #00E0FF;
          }

          .text-primary {
            color: #007bff;
          }
        `}
      </style>
    </div>
  );
};

export default Layout;
