import React from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";

const AboutMe = () => {
  const careerTimeline = {
    labels: [
      "General Store Manager (2012-2014)",
      "General Manager (2015-2017)",
      "General Manager (2017-2019)",
      "Talent Acquisition Manager (2017-2018)",
      "Executive Director of Operations (2018-Present)",
      "Manager of Mobility Services Data (2021-Present)",
    ],
    datasets: [
      {
        label: "Career Progression",
        data: [2, 5, 7, 6, 13, 18],
        borderColor: "#00E0FF",
        backgroundColor: "rgba(0, 224, 255, 0.3)",
        tension: 0.4,
      },
    ],
  };

  return (
    <div className="container my-5">
      {/* Introduction */}
      <section className="text-center mb-5">
        <h1 className="fw-bold display-4">About Me</h1>
        <h4 className="fw-bold text-primary">
          Total Years of Experience: 18 Years
        </h4>
      </section>

      {/* Career Timeline and Expertise Side by Side */}
      <section className="row align-items-center mb-5">
        <div className="col-md-6">
          <h2 className="text-center mb-4">My Expertise</h2>
          <p className="text-center">
            Over the years, I have accumulated a diverse set of skills through
            various leadership and executive roles.
          </p>
          <ul>
            <li>
              <strong>Executive Leadership & Business Management:</strong>{" "}
              Managed large-scale operations, strategic planning, financial
              oversight, HR, compliance, and corporate restructuring.
            </li>
            <li>
              <strong>Data Analytics & AI-Driven Decision Making:</strong>{" "}
              Utilized advanced data monitoring techniques, predictive modeling,
              and business intelligence tools to optimize decision-making and
              improve performance.
            </li>
            <li>
              <strong>
                Public Policy, Compliance & Regulatory Management:
              </strong>{" "}
              Ensured operations comply with industry regulations, monitored
              audits, enforced policy frameworks, and contributed to governance
              initiatives.
            </li>
            <li>
              <strong>Talent Acquisition & Workforce Development:</strong> Led
              recruitment strategies, training programs, and employee retention
              initiatives for high-performing teams.
            </li>
            <li>
              <strong>Operational & Service Optimization:</strong> Designed and
              implemented data-driven solutions to improve service delivery,
              financial efficiency, and workflow optimization.
            </li>
            <li>
              <strong>Investment & Trading:</strong> Developed AI-driven stock
              market strategies, actively investing in Amazon, Moderna,
              Salesforce, Microsoft, Tesla, and Nvidia.
            </li>
          </ul>
        </div>
        <div className="col-md-6">
          <h2 className="text-center">Career Progression</h2>
          <Line
            data={careerTimeline}
            options={{ scales: { y: { suggestedMax: 18, beginAtZero: true } } }}
          />
        </div>
      </section>

      {/* Awards and Education Side by Side */}
      <section className="row align-items-center my-5">
        <div className="col-md-6 text-center">
          <h2>Education</h2>
          <p>
            🎓 <strong>Master of Science in Data Science</strong> (Expected
            07/2025)
          </p>
          <p>
            🎓 <strong>Bachelor of Arts in Political Science</strong>{" "}
            (2014-2020)
          </p>
        </div>

        <div className="col-md-6">
          <h2 className="text-center mb-4">Awards & Recognitions</h2>
          <ul>
            <li>
              🏆 Certificate of Appreciation - Transportation Advisory Board at
              Denver International Airport (2019)
            </li>
            <li>
              📜 David Langston Excellence Award - Central Florida Community
              College (2009)
            </li>
          </ul>
        </div>
      </section>

      {/* Business and Hobbies Side by Side */}
      <section className="row align-items-center my-5">
        <div className="col-md-6">
          <h2 className="text-center mb-4">Hobbies & Interests</h2>
          <ul>
            <li>
              ⚽ <strong>Soccer:</strong> Passionate fan of Al Ahly SC and the
              Egyptian National Team. Plays as a center midfielder.
            </li>
            <li>
              🎵 <strong>Music:</strong> Enjoys Mohamed Mounir, Bob Marley,
              Aerosmith, Cairokee, and Queen.
            </li>
            <li>
              📜 <strong>Egyptian Poetry:</strong> Deep appreciation for works
              by Abdel Rahman al-Abnoudy, Salah Jahin, and Ahmed Fouad Negm.
            </li>
            <li>
              💻 <strong>Coding - JavaScript:</strong> Enthusiastic about
              full-stack development with JavaScript, including MERN stack
              applications.
            </li>
            <li>
              🌍 <strong>World Politics:</strong> Strong analytical background
              in political science, governance, and global relations.
            </li>
            <li>
              📊 <strong>Data Science:</strong> Skilled in R, Python, and
              Tableau for analytics and dashboard development.
            </li>
            <li>
              🤖 <strong>Machine Learning & AI:</strong> Passionate about deep
              learning, NLP, and AI-powered automation solutions.
            </li>
          </ul>
        </div>
        <div className="col-md-6">
          <h2 className="text-center mb-4">Businesses</h2>
          <ul>
            <li>
              <strong>Ones I Own:</strong>
            </li>
            <ul>
              <li>
                🚖 <strong>Trans Voyage Taxi:</strong> Partner in a leading
                transportation service provider in Denver, CO.
              </li>
              <li>
                🏢 <strong>Old Alex Hub, LLC:</strong> Overseeing ventures in
                stocks, AI, and future projects.
              </li>
            </ul>
            <li>
              <strong>Ones I Helped Start:</strong>
            </li>
            <ul>
              <li>
                🚕 <strong>Denver Taxi & Alpine Taxi:</strong> Advisor,
                assisting in business development and strategy.
              </li>
              <li>
                📈 <strong>Green Taxi Cooperative:</strong> Led its launch,
                wrote operational manuals, and developed its hiring strategies.
              </li>
            </ul>
            <li>
              <strong>Ones I Reformed:</strong>
            </li>
            <ul>
              <li>
                🚗 <strong>Mile High Cab, Inc.:</strong> Led organizational
                restructuring, hiring improvements, and operational reforms at
                age 22.
              </li>
            </ul>
          </ul>
        </div>
      </section>

      {/* Philosophy Section */}
      <section className="text-center my-5">
        <h2>My Philosophy</h2>
        <blockquote className="philosophy-text">
          I believe life is a constant interplay between tradition and
          innovation, where we honor the past while boldly shaping the future.
          My journey rooted in Egyptian culture, a love for soccer, music, and
          poetry, and a passion for coding and AI has taught me that real
          progress happens when we blend human empathy with intelligent
          problem-solving. Whether exploring the depths of political theory or
          experimenting with the latest technology, I strive to stay curious,
          embrace new ideas, and uplift others. Each experience, be it a
          challenge or triumph, is a stepping stone toward greater wisdom,
          collaboration, and understanding... a path I pursue wholeheartedly to
          build a life that’s both meaningful and impactful.
        </blockquote>
      </section>
    </div>
  );
};

export default AboutMe;
