import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [isDarkMode, setIsDarkMode] = useState(
    window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
  );

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_GET_ARTICLES);
        if (response.status === 200) {
          setProjects(response.data.articles);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchProjects();

    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleThemeChange = (e) => setIsDarkMode(e.matches);
    mediaQuery.addEventListener("change", handleThemeChange);
    return () => mediaQuery.removeEventListener("change", handleThemeChange);
  }, []);

  function truncateText(text, maxWords) {
    const cleanedText = text.replace(/^['"*]+|['"*]+$/g, "").replace(/\*/g, "");
    const words = cleanedText.split(" ");
    return words.length > maxWords
      ? words.slice(0, maxWords).join(" ") + "..."
      : cleanedText;
  }

  return (
    <div
      className={`container my-5 p-4 rounded ${
        isDarkMode ? "text-light bg-dark" : "text-dark bg-light"
      }`}
    >
      <section className="text-center mb-5">
        <h1 className="fw-bold display-4">Projects</h1>
        <p className={isDarkMode ? "text-secondary" : "text-muted"}>
          Explore my work in AI, Data Science, and Full-Stack Development.
        </p>
      </section>

      <div className="row">
        {projects.map((project) => (
          <div key={project._id} className="col-md-4 mb-4">
            <div
              className={`card shadow-sm h-100 d-flex flex-column ${
                isDarkMode ? "bg-secondary text-light" : "bg-white text-dark"
              }`}
            >
              <img
                src={project.image}
                className="card-img-top"
                alt={project.title}
              />
              <div className="card-body d-flex flex-column">
                <h5
                  className={`card-title ${
                    isDarkMode ? "text-white" : "text-dark"
                  }`}
                >
                  {project.title}
                </h5>
                <p
                  className={`mb-1 ${isDarkMode ? "text-light" : "text-muted"}`}
                >
                  <strong>Published By:</strong> {project.publishedBy}
                </p>
                <p
                  className={`mb-1 ${isDarkMode ? "text-light" : "text-muted"}`}
                >
                  <strong>Date:</strong>{" "}
                  {new Date(project.date).toLocaleDateString()}
                </p>
                <p
                  className={`card-text flex-grow-1 ${
                    isDarkMode ? "text-light" : "text-dark"
                  }`}
                >
                  {truncateText(project.sections.Introduction, 35)}
                </p>
                <Link
                  to={`/projects/${project._id}`}
                  className={`btn mt-auto ${
                    isDarkMode ? "btn-outline-light" : "btn-primary"
                  }`}
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
